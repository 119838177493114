<template>
  <router-link :to="{
    path: '/artCollege',
    query: { cid: item.id, sid: item.school_id, degree: item.degree, w_score_line: this.globalData[0], z_score_line: this.globalData[1] },
  }" target="_blank" class="college">
    <el-row>
      <el-col :span="3">
        <el-avatar class="school_logo" :size="120" :src="item.logo" />
      </el-col>
      <el-col :span="18">
        <p class="row" style="color:#666666">
          <span class="name">{{ item.art_school.name }}</span>
          <span v-for="(tip, i) in item.school_tip_id" :key="i" class="tag">{{
            tip
          }}</span>
          <span v-if="item.school_hot > 0" class="view">
            <i class="iconfont icon-hot" />{{ item.school_hot }}
          </span>
        </p>
        <p>
          <span v-if="item.school_code" style="color: #666666">学校标识码：{{ item.school_code }}
          </span> <span style="color: #666666;margin-left: 20px;"> 专业录取规则：{{ item.art_school.lqgz }} </span>
        </p>
        <p class="row info" style="color:#666666">
          <span>类型：{{ item.school_type }}</span>
          <span v-if="item.school_belong">隶属：{{ item.school_belong }}</span>
          <span>办学性质：{{ item.school_nature }}</span>
          <span>属地：{{ item.address }}</span>
          <span v-if="item.school_id">在豫招生代号：{{ item.school_id }}</span>
        </p>
      </el-col>
      <el-col :span="3">
        <div style="line-height: 126px">
          <span v-if="att === 1" class="guanzhu" @click.stop.prevent="attention()">取消关注
          </span>
          <span v-else class="guanzhu" @click.stop.prevent="attention()">+关注</span>
        </div>
      </el-col>
    </el-row>
  </router-link>
</template>

<script>
export default {
  name: "CollegeList",
  props: ["item"],
  data() {
    return {
      att: 0,
    };
  },
  mounted() {
    if (this.item.is_attention) {
      this.att = this.item.is_attention;
    }
  },
  inject: ['globalData'],
  methods: {
    attention() {
      this.$fecth
        .post("user_info/addAttention", {
          id: this.item.id,
          type: 0,
        })
        .then(() => {
          if (this.att === 1) {
            this.att = 0;
            this.$notify({
              offset: 400,
              showClose: true,
              message: "取消关注",
              type: "success",
            });
          } else {
            this.att = 1;
            this.$notify({
              offset: 400,
              showClose: true,
              message: "已关注",
              type: "success",
            });
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.college {
  display: block;
  padding: 15px 30px;
  border: 1px solid #dfdfdf;
  border-top: 0;

  .school_logo {
    position: relative;
    top: 10px;
  }

  .row {
    height: 60px;
    line-height: 60px;

    span {
      margin-right: 18px;
    }

    &.info {
      width: 120%;
    }

    .name {
      font-size: 18px;
    }

    .view {
      color: #d30d1c;
      font-size: 13px;

      .iconfont {
        color: #d30d1c;
        font-size: 22px;
      }
    }

    .tag {
      padding: 5px 14px;
      font-size: 12px;
      border-radius: 40px;
      border: 1px solid #afaeae;
      color: #666666;
      position: relative;
      top: -5px;
    }
  }

  .guanzhu {
    color: #1787e0;
    padding: 5px 25px;
    border-radius: 50px;
    margin-top: 60px;
  }
}
</style>
