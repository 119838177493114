<template>
  <div>
    <div>

      <conditional-search @onSearch="onSearch"></conditional-search>

      <div v-loading="loading" style="min-height:300px">
        <CollegeList v-for="(item, i) in list" :key="i" :item="item"></CollegeList>
      </div>

      <!--        分页-->
      <div class="pagination">
        <el-pagination :hide-on-single-page="true" :page-size="pageSize" :current-page="pageNum" :pager-count="11"
          :total="total" class="page-box" layout="prev, pager, next" @current-change="pageChange" />
      </div>
    </div>
  </div>
</template>

<script>

import conditionalSearch from './components/ConditionalSearchCollege'
import CollegeList from './components/CollegeList';
import { mapState } from "vuex";
export default {
  name: '',
  data() {
    return {
      list: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      loading: true,
      check: {}
    };
  },
  watch: {
    art_batch() {
      this.getCollege(this.check)
    }
  },
  props: [
    'art_batch'
  ],
  components: {
    conditionalSearch,
    CollegeList
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.getCollege(this.check)
  },
  methods: {
    getCollege(obj) {
      this.loading = true
      let data = obj
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      data.is_wenli = this.userInfo.is_wenli
      data.art_batch = this.art_batch,
        this.$fecth.post('art_school/getSchoolList', data).then((res) => {
          this.loading = false
          this.list = res.lists
          this.total = res.total
        })
    },
    pageChange(page) {
      document.body.scrollTop = document.documentElement.scrollTop = 650
      this.pageNum = page
      this.getCollege(this.check)
    },
    onSearch(data) {
      this.check = data
      this.pageNum = 1
      this.getCollege(data)
    },
  },
};
</script>

<style scoped lang='less'>
.pagination {
  padding: 40px 0;

  .page-box {
    text-align: center;
  }
}
</style>
